























































































import Vue from "vue";
import { api } from "@/api/api";
import {
  ApiGetCommunicationTemplateDto,
  ApiCreateCommunicationTemplateDto,
  ApiUpdateCommunicationTemplateDto,
  ApiGetCommunicationTemplateGroupDto,
} from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { Editor } from "@toast-ui/vue-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@/shared/styles/toastui-editor.scss";

enum Field {
  SUBJECT = "subject",
  CONTENT = "content",
}

export default defineComponent({
  name: "CommunicationTemplateModal",
  components: { BaseModalForm, Editor },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    existingItemModal: {
      type: Object as PropType<ApiGetCommunicationTemplateDto>,
    },
    existingItemId: {
      type: Number,
    },
    templateGroups: {
      type: Array as PropType<ApiGetCommunicationTemplateGroupDto[]>,
      required: true,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const messagingOptions = ref<string[] | null>();
    const selectedVariable = ref("");
    const currentOrganizationName = ref<string | null>();

    const TEMPLATE_USER_GROUP_TYPE = "user";
    const template = ref<ApiCreateCommunicationTemplateDto | ApiUpdateCommunicationTemplateDto>(
      getInitialCommunicationTemplateData()
    );
    const templateId = props.existingItemId ?? 0;
    const isUserGroupType = ref(true);

    if (props.modalType === ModalType.Edit) {
      isUserGroupType.value = props.existingItemModal?.communicationTemplateGroupType === TEMPLATE_USER_GROUP_TYPE;
      template.value = deepCloneObject(props.existingItemModal as ApiUpdateCommunicationTemplateDto);
    }

    const filteredTemplateGroups = isUserGroupType.value
      ? props.templateGroups.filter((template) => template.type === TEMPLATE_USER_GROUP_TYPE)
      : props.templateGroups;

    const loadMessagingOptions = async () => {
      messagingOptions.value = (await api.messaging.getMessagingDefaultOptions()).data?.variables;
    };

    const loadCurrentOrganizationName = async () => {
      currentOrganizationName.value = (await api.user.getCurrentEmployeeAsync()).data?.currentOrganizationName;
    };

    const handleSubmit = () => {
      const isValid = getValidatableRef(refs.connectForm)?.validate();
      if (!isValid) {
        openNotification(store, NotificationItemType.Error, "Mangler malgruppe, malnavn eller tittel");
        return;
      }
      if (!template.value.content) {
        openNotification(store, NotificationItemType.Error, "Innhold til kommunikasjonsmal er påkrevd");
        return;
      }
      if (props.modalType === ModalType.Add) {
        createCommunicationTemplate();
      } else if (props.modalType === ModalType.Edit) {
        updateCommunicationTemplate();
      } else {
        openNotification(store, NotificationItemType.Error, "Det skjedde en feil");
      }
    };

    const createCommunicationTemplate = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.communicationtemplate.createCommunicationTemplateAsync(template.value);
        openNotification(store, NotificationItemType.Success, "Kommunikasjonsmal er opprettet");
        emit("close");
      });
    };

    const updateCommunicationTemplate = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.communicationtemplate.updateCommunicationTemplateAsync(templateId, template.value);
        openNotification(store, NotificationItemType.Success, "Kommunikasjonsmal er lagret");
        emit("close");
      });
    };

    const getSubjectRef = () => (refs.subjectTextField as Vue).$el.querySelector("input");
    const getEditorRef = () => refs.tuiEditor as Editor;

    const onEditorChange = () => {
      template.value.content = getEditorRef().invoke("getMarkdown");
    };

    const variableHandlers: Record<Field, () => void> = {
      [Field.SUBJECT]: () => addVariableToSubject(),
      [Field.CONTENT]: () => getEditorRef().invoke("insertText", selectedVariable.value),
    };

    const addVariable = (target: Field) => {
      if (!selectedVariable.value) {
        openNotification(store, NotificationItemType.Error, "Kunne ikke legge til en tom variabel");
        return;
      }
      variableHandlers[target]();
    };

    const addVariableToSubject = () => {
      const cursorPos = getSubjectRef()?.selectionStart ?? 0;
      const existingText = getSubjectRef()?.value;
      const textBefore = existingText?.substring(0, cursorPos);
      const textAfter = existingText?.substring(cursorPos, existingText?.length);
      template.value.subject = textBefore + selectedVariable.value + textAfter;
    };

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await loadMessagingOptions();
        await loadCurrentOrganizationName();
      });
    });

    return {
      handleSubmit,
      validateNotEmpty,
      template,
      messagingOptions,
      selectedVariable,
      currentOrganizationName,
      addVariable,
      editorOptions,
      onEditorChange,
      isUserGroupType,
      filteredTemplateGroups,
      Field,
    };
  },
});
const getInitialCommunicationTemplateData = (): ApiCreateCommunicationTemplateDto => ({
  communicationTemplateGroupId: 0,
  name: "",
  subject: "",
  content: "",
  isActive: true,
});

const editorOptions = {
  minHeight: "200px",
  language: "nb-NO",
  useCommandShortcut: true,
  usageStatistics: true,
  hideModeSwitch: false,
  toolbarItems: [
    ["heading", "bold", "italic", "strike"],
    ["hr", "quote"],
    ["ul", "ol", "task", "indent", "outdent"],
    ["link"],
  ],
  placeholder: "Skriv inn innhold",
};
