






import { api } from "@/api/api";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "CommunicationTemplateDeleteModal",
  components: { BaseConfirmModalForm },
  props: {
    modalBaseData: {
      type: Object as PropType<ModalBaseData>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();

    const deleteCommunicationTemplate = async (modalData: ModalBaseData) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.communicationtemplate.deleteCommunicationTemplateAsync(modalData.existingItemId);
        openNotification(store, NotificationItemType.Success, "Kommunikasjonsmalen er slettet");
        emit("close");
      });
    };

    return {
      deleteCommunicationTemplate,
    };
  },
});
